// FOR ADD MEMBER
export const ADD_MEMBER_REQUEST = "ADD_MEMBER_REQUEST"
export const ADD_MEMBER_SUCCESS = "ADD_MEMBER_SUCCESS"
export const ADD_MEMBER_FAILURE = "ADD_MEMBER_FAILURE"

// FOR ADD MEMBER
export const MEMBER_REQUEST = "MEMBER_REQUEST"
export const MEMBER_SUCCESS = "MEMBER_SUCCESS"
export const MEMBER_FAILURE = "MEMBER_FAILURE"

// FOR ADD MEMBER
export const SINGLE_MEMBER_REQUEST = "SINGLE_MEMBER_REQUEST"
export const SINGLE_MEMBER_SUCCESS = "SINGLE_MEMBER_SUCCESS"
export const SINGLE_MEMBER_FAILURE = "SINGLE_MEMBER_FAILURE"