// FOR POST EXPENSE 
export const POST_GROUP_REQUEST = "POST_GROUP_REQUEST"
export const POST_GROUP_SUCCESS = "POST_GROUP_SUCCESS"
export const POST_GROUP_FAILURE = "POST_GROUP_FAILURE"

export const ACCOUNT_REQUEST = "ACCOUNT_REQUEST"
export const ACCOUNT_SUCCESS = "ACCOUNT_SUCCESS"
export const ACCOUNT_FAILURE = "ACCOUNT_FAILURE"

export const GROUP_REQUEST = "GROUP_REQUEST"
export const GROUP_SUCCESS = "GROUP_SUCCESS"
export const GROUP_FAILURE = "GROUP_FAILURE"
