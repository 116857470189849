// FOR ACTIVE LOAN
export const LOAN_REQUEST = "LOAN_REQUEST"
export const LOAN_SUCCESS = "LOAN_SUCCESS"
export const LOAN_FAILURE = "LOAN_FAILURE"

// FOR GROUP LOAN
export const GROUP_LOAN_REQUEST = "GROUP_LOAN_REQUEST"
export const GROUP_LOAN_SUCCESS = "GROUP_LOAN_SUCCESS"
export const GROUP_LOAN_FAILURE = "GROUP_LOAN_FAILURE"


// FOR GROUP LOAN
export const GROUP_PAYMENT_REQUEST = "GROUP_PAYMENT_REQUEST"
export const GROUP_PAYMENT_SUCCESS = "GROUP_PAYMENT_SUCCESS"
export const GROUP_PAYMENT_FAILURE = "GROUP_PAYMENT_FAILURE"

// FOR ACTIVE LOAN
export const ACTIVE_LOAN_REQUEST = "ACTIVE_LOAN_REQUEST"
export const ACTIVE_LOAN_SUCCESS = "ACTIVE_LOAN_SUCCESS"
export const ACTIVE_LOAN_FAILURE = "ACTIVE_LOAN_FAILURE"

// FOR CLOSED LOAN 
export const CLOSED_LOAN_REQUEST = "CLOSED_LOAN_REQUEST"
export const CLOSED_LOAN_SUCCESS = "CLOSED_LOAN_SUCCESS"
export const CLOSED_LOAN_FAILURE = "CLOSED_LOAN_FAILURE"

// FOR OVERDUE LOAN 
export const OVERDUE_LOAN_REQUEST = "OVERDUE_LOAN_REQUEST"
export const OVERDUE_LOAN_SUCCESS = "OVERDUE_LOAN_SUCCESS"
export const OVERDUE_LOAN_FAILURE = "OVERDUE_LOAN_FAILURE"

// FOR CREATE SAVING 
export const CREATE_LOAN_REQUEST = "CREATE_LOAN_REQUEST"
export const CREATE_LOAN_SUCCESS = "CREATE_LOAN_SUCCESS"
export const CREATE_LOAN_FAILURE = "CREATE_LOAN_FAILURE"

// FOR APPROVED LOAN 
export const APPROVED_LOAN_REQUEST = "APPROVED_LOAN_REQUEST"
export const APPROVED_LOAN_SUCCESS = "APPROVED_LOAN_SUCCESS"
export const APPROVED_LOAN_FAILURE = "APPROVED_LOAN_FAILURE"