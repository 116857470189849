// FOR SAVINGS
export const SAVING_TRANSACTION_REQUEST = "SAVING_TRANSACTION_REQUEST"
export const SAVING_TRANSACTION_SUCCESS = "SAVING_TRANSACTION_SUCCESS"
export const SAVING_TRANSACTION_FAILURE = "SAVING_TRANSACTION_FAILURE"

// FOR LOANS
export const LOAN_TRANSACTION_REQUEST = "LOAN_TRANSACTION_REQUEST"
export const LOAN_TRANSACTION_SUCCESS = "LOAN_TRANSACTION_SUCCESS"
export const LOAN_TRANSACTION_FAILURE = "LOAN_TRANSACTION_FAILURE"

// FOR LOANS
export const TRANSACTION_REQUEST = "TRANSACTION_REQUEST"
export const TRANSACTION_SUCCESS = "TRANSACTION_SUCCESS"
export const TRANSACTION_FAILURE = "TRANSACTION_FAILURE"