
// FOR ACTIVE SAVING
export const SAVING_REQUEST = "SAVING_REQUEST"
export const SAVING_SUCCESS = "SAVING_SUCCESS"
export const SAVING_FAILURE = "SAVING_FAILURE"



// FOR ACTIVE SAVING
export const ACTIVE_SAVING_REQUEST = "ACTIVE_SAVING_REQUEST"
export const ACTIVE_SAVING_SUCCESS = "ACTIVE_SAVING_SUCCESS"
export const ACTIVE_SAVING_FAILURE = "ACTIVE_SAVING_FAILURE"

// FOR CLOSED SAVING 
export const CLOSED_SAVING_REQUEST = "CLOSED_SAVING_REQUEST"
export const CLOSED_SAVING_SUCCESS = "CLOSED_SAVING_SUCCESS"
export const CLOSED_SAVING_FAILURE = "CLOSED_SAVING_FAILURE"

// FOR OVERDUE SAVING 
export const OVERDUE_SAVING_REQUEST = "OVERDUE_SAVING_REQUEST"
export const OVERDUE_SAVING_SUCCESS = "OVERDUE_SAVING_SUCCESS"
export const OVERDUE_SAVING_FAILURE = "OVERDUE_SAVING_FAILURE"

// FOR CREATE SAVING 
export const CREATE_SAVING_REQUEST = "CREATE_SAVING_REQUEST"
export const CREATE_SAVING_SUCCESS = "CREATE_SAVING_SUCCESS"
export const CREATE_SAVING_FAILURE = "CREATE_SAVING_FAILURE"