//FOR BANKS
export const BANK_REQUEST = 'BANK_REQUEST'
export const BANK_SUCCESS = 'BANK_SUCCESS'
export const BANK_FALIURE = 'BANK_FALIURE'

//FOR BANKS
export const ACCOUNT_NAME_REQUEST = 'ACCOUNT_NAME_REQUEST'
export const ACCOUNT_NAME_SUCCESS = 'ACCOUNT_NAME_SUCCESS'
export const ACCOUNT_NAME_FALIURE = 'ACCOUNT_NAME_FALIURE'

//FOR CASH PAYMENT
export const CASH_PAYMENT_REQUEST = 'CASH_PAYMENT_REQUEST'
export const CASH_PAYMENT_SUCCESS = 'CASH_PAYMENT_SUCCESS'
export const CASH_PAYMENT_FALIURE = 'CASH_PAYMENT_FALIURE'

//FOR CASH PAYMENT
export const DEBIT_PAYMENT_REQUEST = 'DEBIT_PAYMENT_REQUEST'
export const DEBIT_PAYMENT_SUCCESS = 'DEBIT_PAYMENT_SUCCESS'
export const DEBIT_PAYMENT_FALIURE = 'DEBIT_PAYMENT_FALIURE'

//FOR CASH PAYMENT
export const EMANDATE_REQUEST = 'EMANDATE_REQUEST'
export const EMANDATE_SUCCESS = 'EMANDATE_SUCCESS'
export const EMANDATE_FALIURE = 'EMANDATE_FALIURE'