//FOR Registration
export const REGISTER_USER_REQUEST = 'REGISTER_USER_REQUEST'
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS'
export const REGISTER_USER_FAILURE = 'REGISTER_USER_FALIURE'
export const TRANSFER_DATA = 'TRANSFER_DATA'

// FOR VERIFY OTP
export const OTP_VERIFY_REQUEST = "OTP_VERIFY_REQUEST"
export const OTP_VERIFY_SUCCESS = "OTP_VERIFY_SUCCESS"
export const OTP_VERIFY_FAILURE = "OTP_VERIFY_FAILURE"
